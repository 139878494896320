.p-overlay {
  width: 100%;
}

.p-panelmenu-item-content:hover {
  cursor: pointer;
}

.d-table {
  & .p-button {
    vertical-align: middle;
    padding-block: 0.35rem;

    font-size: 0.77rem;
  }
}

.p-button {
  border: none !important;
  white-space: nowrap;

  &:focus {
    outline: none;
  }

  &.p-button-secondary {
    background-color: theme('system.secondary.background.500');
    color: theme('system.secondary.text.500');

    &:hover {
      background: theme('system.secondary.background.700') !important;
      color: theme('system.secondary.text.500') !important;
    }
  }

  &.p-button-danger {
    background-color: theme('system.danger.background.500');
    color: theme('system.danger.text.500');

    &:hover {
      background: theme('system.danger.background.700') !important;
      color: theme('system.danger.text.500') !important;
    }
  }

  &.p-button-warn {
    background-color: theme('system.warning.background.500');
  }
}

.p-tag {
  &.p-tag-success {
    background: theme('system.success.background.500');
    color: theme('system.success.text.500');
  }

  &.p-tag-info {
    background: var(--p-button-info-background);
    color: var(--p-button-info-color);
  }

  &.p-tag-danger {
    background: theme('system.danger.background.500');
    color: theme('system.danger.text.500');
  }

  &.p-tag-warn {
    background: theme('system.warning.background.500');
    color: theme('system.warning.text.500');
  }
}

.p-tab-active {
  font-weight: bold !important;
}

.p-tabs .p-tablist .p-tablist-nav-button {
  box-shadow: 0px 0px 10px 50px rgb(239, 243, 248, 0.6);
}

.p-message {
  border-left-width: 5px !important;

  &.p-message-warn {
    border-left-color: var(--p-message-warn-color) !important;
  }

  &.p-message-info {
    border-left-color: var(--p-message-info-color) !important;
  }

  &.p-message-error {
    border-left-color: var(--p-message-error-color) !important;
  }

  &.p-message-success {
    border-left-color: var(--p-message-success-color) !important;
  }

  &.p-message-secondary {
    border-left-color: var(--p-message-secondary-color) !important;
  }

  &.p-message-contrast {
    border-left-color: var(--p-message-contrast-color) !important;
  }

  & .p-message-icon {
    font-size: 1.3rem !important;
    margin-right: 0.5rem;
  }
}

.p-autocomplete.readonly-chips, .p-multiselect.readonly-chips {
  & .p-chip-remove-icon {
    display: none;
  }
}
//
//.p-card {
//  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
//  shadow-color: green;
//}
