@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'primeicons/primeicons.css';
@import './primng.scss';

body {
  margin: 0;
}

.loading-indication {
  max-width: min(550px, 90vw);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.startup-logo {
  max-width: min(500px, 90vw);
  margin-bottom: 25px;
}

@media print {
  #printContainer { width: 99% !important; }
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: theme('colors.primary-500');
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: theme('colors.surface-500');
  cursor: ns-resize;
}

.system-background-linear {
  background: linear-gradient(165deg, theme('system.background.background.500') 40%, theme('colors.primary-500'));
}

.system-backgrounp-card {
  background: linear-gradient(165deg, theme('system.background.background.500') 50%, rgba(215, 183, 63, 0.1));
}

.system-background-content {
  background: linear-gradient(170deg, theme('system.background.background.500') 95%, theme('colors.primary-500'));
}

.system-background {
  background: theme('system.background.background.500');
}

.p-card {
  z-index: 0;

  & .p-card-content {
    z-index: 10;
  }
}
